@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap');
*,
*::after,
*::before {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

body{
    font-family: 'Poppins', sans-serif;
}

html{
    font-size: 10px;
}

ul{
    list-style: none;
}

a{
    text-decoration: none;
    color: rgba(121, 118, 118, 0.959);      
    font-size: 2rem;
}

.error{
    color: orangered;
}

.grad{
    background-image: linear-gradient(to right, rgb(12, 130, 240), rgb(16, 12, 131));
}
.app-container{
    width: 1200px;
}

.center{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


/* ############################# Home ############################ */

.container{
    height: 95vh;
    background-image: url("../../public/img/Vector\ Image.svg");
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
}

.main-container{
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translate(-50%,-50%);
    padding: 1.5rem;
    font-size: 1.8rem;
    text-align: center;
    width: 50%;
}

.main-heading h1{
    color: rgba(48, 46, 46, 0.966);
    font-weight: 600;
}

.main-span{
   color:  rgba(12, 130, 240);
}

.main-btn{
    display: inline-block;
    padding: 1.5em 3.5em;
    text-decoration: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 2rem;
}

/* ###################### Navbar ############################ */

.navbar{
    display: flex;
    justify-content: space-between;
    margin: 2rem 4rem;
}

.nimap-logo img{
    width: 70%;
}

.link{
    display: flex;
    justify-content: space-around;
    margin: 3rem;
}

.link1{
    margin-right: 3rem;
}

/* ######################### Technologies ######################### */

.tech-container{
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    margin: 1.5rem 4rem;
    padding: 1.5rem;
    align-items: center;
}

.teachnologies i{
    cursor: pointer;
}

.md-heading h2{
    color: rgba(48, 46, 46, 0.966);
}

.md-btn a{
    display: inline-block;
    padding: 1.4rem 2.5rem;
    text-decoration: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 1rem;
}


.teachnologies{
    margin: 2rem 4rem;
}

.teachnologies h2{
    color: rgba(48, 46, 46, 0.966);
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    padding: 1.5rem;
}


.backend-container{
    display: flex;
    flex-wrap: wrap;
    padding:0.5rem
}

.backend-box{
    border: 2px solid rgba(12, 130, 240);
    width: 48%;
    height: 14rem;
    border-radius: 10px;
    margin: 1rem;
    display: flex;
    align-items: center;
}

.text-container{
    color: rgba(48, 46, 46, 0.966);
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 1.5rem;
}

.text-container h1{
    font-size: 2.2rem;
    font-weight: 500;
}

.add-remove{
    margin: 3.5rem;
    font-size: 1.5rem;
    color: rgba(12, 130, 240);
}

.count{
    margin: 2rem;
    color: black;
    font-weight: 500;
    font-size: 2.5rem;
}

.reset{
    font-size: 1.5rem;
    border: none;
    color: rgba(12, 130, 240);
    background-color: white;
    margin: 3rem;
    font-weight: 600;
    cursor: pointer;
}

/* ########################## logo ################################## */

.logo img{
    margin: 2rem;
    width: 8rem;
}

/* ########################## Contact Footer ############################ */

.footer-container{
    display: flex;
    color: white;
    padding: 4rem;
}

.box{
    width: 25rem;
    margin-left: 4rem;
    line-height: 2.2rem;
}

.footer h2{
    font-size: 1.6rem;
    font-weight: 400;
}

.footer p{
    font-size: 1.2rem;
    font-weight: 400;
}

/* ################# Contact us page ##################### */

.contact-page{
    height: 50vh;
    text-align: center;
    padding-top: 6.5rem;
}

.contact-page h1{
    color: rgba(48, 46, 46, 0.966);
    font-size: 3.5rem;
    font-weight: 600;
}
.contact-page h2{
    color: rgba(66, 64, 64, 0.966);
    font-size: 2rem;
    font-weight: 600;
}

/* ################## Get Quote ############################ */

.background-container{
    position: relative;
    height: 41vh;
}

.home{
    filter: blur(8px);  
    -webkit-filter: blur(3px); 
    backdrop-filter: blur(100px);
}

.form-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    padding: 2rem;
    width: 27%;
    height: auto;
    background-color: white;
    box-shadow: 1px 2px 10px black;
    border-radius: 5%;
}

.form-btn{
    display: inline-block;
    padding: 1.3rem 5.5rem;
    text-decoration: none;
    border-radius: 50px;
    outline: none;
    cursor: pointer;
    border: none;
    color: white;
    font-weight: 500;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-left: 7rem;
}

.form{
    padding: 1.5rem;
}


.input{
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    border: 1.9px solid rgba(12, 130, 240);
    outline: none;
}
#phoneId{
    border: none;
    outline: none;
    
}
.number{
    width: 70%;
}

.country-code{
    width: 18%;
    padding: 1rem;
    border-radius: 10px;
    margin-right: 0.5rem;
    text-align: center;
}

.form-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.close{
    background-color: white;
    font-size: 2rem;
    border: none;
    font-weight: 600;
}

.form-heading{
    color: rgba(48, 47, 46, 0.966);
    font-size: 1.7rem;
    font-weight: 600;
}


/* ##################### Media ############################# */
@media (min-width: 100px) and (max-width: 299px){

    .navbar{
        margin: 0.5rem;
    }

    .nimap-logo img{
        width: 6rem;
        margin-left: 1rem;
        margin-top: 0.5rem;
    }

    .number  {
        width: 72%;
    }

    .country-code{
        width: 23%;
        padding: 1rem 0.5rem;
    }

    .form-btn {
        margin-top: 2rem;
        margin-left: 1.5rem;
    }

    .link{
        margin: 1rem;
    }

    .link1{
        margin-right: 3rem;
    }
    .link a{
        font-size: 1rem;
    }

    .link2{
        margin-left: 0px;
    }


    .main-container{
        font-size: 0.5rem;
        width: 72%;
        margin-top: 1rem;
    }
    .main-btn{
        padding: 0.5em 1em;
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
    }
    .main-heading h1{
        font-size: 1rem;
    }
    .container{
        height: 32vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .md-heading h2{
        font-size: 1.2rem;
        margin: 10px;
    }
    .reset{
        font-size: 1rem;
    }
    .md-btn{
        display: flex;
        height: 5rem;
        align-items: center;
        width: 100%;
    }
    .md-btn a{
       font-size: 1rem;
       margin-right: 0px;
       padding: 0.8rem 0.9rem;
    }

    .backend-box{
        width: 100%;
       height: 10rem;
    }

    .tech-container{
        display: flex;
        align-items: center;
        margin: 0px;
        margin-top: -23px;
        padding: 5px;
    }
    
    .teachnologies{
        width: 100%;
        margin: 1px;
    }

    .teachnologies h2{
        margin: 10px;
        font-size: 1.5rem;
    }
    
    .text-heading h1{
        font-size: 1.2rem;
        margin-left: 0px;
    }

    .text-container{
        margin: 0px;
        padding: 0px;
    }

    .text-container span{
        font-size: 1.5rem;
    }

    .add-remove{
        width: 62%;
        margin: 0px;
    }

    .logo img{
        padding: 0px;
        width: 6rem;
        margin: 1.5rem;
    }

    .footer-container{
        display: block;
        padding: 1rem;
    }
    .box{
        margin: 1rem;
    }

    .form-container{
        width: 87%;
        height: auto;
        position: absolute;
        top: auto;
    }
    .form{
        height: 100%;
    }

    .contact-page{
        height: 24vh;
        padding-top: 2.5rem;
    }

    .contact-page h1{
        font-size: 1.5rem;
    }
    .contact-page h2{
        font-size: 1rem;
    }
    
}

@media (min-width: 300px) and (max-width: 499px){
    .navbar{
      margin: 0.5rem;
    }

    .nimap-logo img{
        width: 8rem;
        margin-left: 2rem;
    }

    .number  {
        width: 81%;
    }

    .country-code{
        width: 16%;
        padding: 1rem 0.5rem;
    }

    .form-btn {
        margin-top: 2rem;
        margin-left: 5.5rem;
    }
    .link a{
        font-size: 1rem;
    }

    .link{
        margin: 2rem;
    }
    .link1{
        margin-right: 1rem;
    }
    .nimap-logo{
        width: 10rem;
    }
    .main-container{
        font-size: 1rem;
        width: 56%;
        margin-top: 2.5rem;
    }
    .main-btn{
        padding: 0.5em 1em;
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
    }
    .main-heading h1{
        font-size: 1rem;
    }
    .container{
        height: 32vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .md-heading h2{
        font-size: 1.2rem;
        margin: 10px;
    }
    .reset{
        font-size: 1rem;
    }
    .md-btn{
        display: flex;
        height: 5rem;
        align-items: center;
    }
    .md-btn a{
       font-size: 1rem;
       margin-right: 0px;
       padding: 0.8rem 0.9rem;
    }

    .backend-box{
        width: 100%;
        font-size: 1rem;
    }

    .tech-container{
        display: flex;
        align-items: center;
        margin: -21px 0.2rem;
    }
    
    .teachnologies{
        width: 100%;
        margin: 1px;
    }

    .teachnologies h2{
        margin: 10px;
    }
    
    .text-heading h1{
        font-size: 1.5rem;
        margin-left: 0px;
    }

    .text-container{
        margin: 0px;
        padding: 0px;
    }

    .text-container span{
        font-size: 1.5rem;
    }

    .add-remove{
        width: 50%;
        margin-right: 1rem;
    }

    .logo img{
        padding: 0px;
        width: 7rem;
        margin: 1.5rem;
    }

    .footer-container{
        display: block;
    }
    .box{
        margin: 1rem;
    }

    .form-container{
        width: 87%;
        height: auto;
        position: absolute;
        top: auto;
    }
    .form{
        height: 100%;
    }

    .contact-page{
        height: 24vh;
        padding-top: 2.5rem;
    }

    .contact-page h1{
        font-size: 1.5rem;
    }
    .contact-page h2{
        font-size: 1rem;
    }

}


@media (min-width: 500px) and (max-width: 600px){
    
    .navbar{
        padding: 0rem;
    }

    .nimap-logo img{
        width: 8rem;
        margin-left: 2rem;
    }

    .number  {
        width: 81%;
    }

    .country-code{
        width: 16%;
        padding: 1rem 0.5rem;
    }

    .form-btn {
        margin-top: 2rem;
        margin-left: 5.5rem;
    }
    .link a{
        font-size: 1rem;
    }

    .link2{
        margin-left: 0px;
    }

    .nimap-logo{
        width: 10rem;
    }
    .main-container{
        font-size: 1rem;
        width: 42%;
        margin-top: 0.5rem;
    }
    .main-btn{
        padding: 0.5em 1em;
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 400;
    }
    .main-heading h1{
        font-size: 1rem;
    }
    .container{
        height: 36vh;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .md-heading h2{
        font-size: 1.2rem;
        margin: 10px;
    }
    .reset{
        font-size: 1rem;
    }
    .md-btn{
        display: flex;
        height: 5rem;
        align-items: center;
    }
    .md-btn a{
       font-size: 1rem;
       margin-right: 0px;
       padding: 0.8rem 1.5rem;
    }

    .backend-box{
        width: 100%;
        font-size: 1rem;
    }

    .tech-container{
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 5px;
    }
    
    .teachnologies{
        width: 100%;
        margin: 1px;
    }

    .teachnologies h2{
        margin: 10px;
    }
    
    .text-heading h1{
        font-size: 1.5rem;
        margin-left: 0px;
    }

    .text-container{
        margin: 0px;
        padding: 0px;
    }

    .text-container span{
        font-size: 1.5rem;
    }

    .add-remove{
        width: 50%;
        margin-right: 1rem;
    }

    .logo img{
        padding: 0px;
        width: 7rem;
        margin: 1.5rem;
    }

    .footer-container{
        display: block;
    }
    .box{
        margin: 1rem;
    }

    .form-container{
        width: 55%;
        height: auto;
        position: absolute;
        top: 60%;
    }
    .form{
        height: 100%;
    }

    .contact-page{
        height: 24vh;
        padding-top: 2.5rem;
    }

    .contact-page h1{
        font-size: 1.5rem;
    }
    .contact-page h2{
        font-size: 1rem;
    }
}

 @media(min-width: 601px) and (max-width: 1000px){
    .container{
        height: 45vh;
        background-position: center;
        background-size: contain;
    }
    .main-heading h1{
        font-size: 2rem;
    }
    .tech-container{
        margin: 0.5rem 0rem;
    }

    .tech-container h1{
        font-size: 2.2rem;
    }
    .md-heading h2{
        margin-left: 2rem;
    }

    .teachnologies h2{
        margin-left: -2rem;
        font-size: 3rem;
        align-items: center;
    }

    .backend-container{
        display: block;
    }
    .backend-box{
        width: 86%;
    }
    .form-container{
        width: 45%;
        height: auto;
    }
    .link2{
        margin-right: 2rem;
    }

    .contact-page{
        height: 65vh;
        padding-top: 20.5rem;
    }

    .md-btn a{
        margin-right: 1rem;
    }

    .main-container{
        margin-top: 2rem;
    }
} 

@media(min-width: 1001px){

    .text-container{
        padding: 0.5rem;
    }

    .text-container h1{
        width: 100%;
        font-size: 1.8rem;
    }
    .form-container{
        width: 35%;
        position: fixed;
    }
    .backend-box{
        width: 48%;
    }
    .container{
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .add-remove{
        width: 12rem;
        margin: 1.5rem;
    }
} 

@media(min-width: 1200px){
    .form-container{
        width: 27%;
    }

    .container{
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: cover;
    }

    .background-container{
        position: relative;
        height: 41vh;
    }

    .form-container{
        position: fixed;
    }

}

